/* @import url('./fonts/NeurialGrotesk/index.css'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;600&display=swap');

@font-face {
  font-family: HelveticaNow;
  src: url('./assets/fonts/HelveticaNowDisplayRegular/font.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNowDisplayRegular/font.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: HelveticaNow;
  src: url('./assets/fonts/HelveticaNowDisplayMd/font.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNowDisplayMd/font.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: HelveticaNow;
  src: url('./assets/fonts/HelveticaNowDisplayBd/font.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNowDisplayBd/font.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: 'Inter', sans-serif !important;
}

body,
.MuiTypography-body1 {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

a.link-blue {
  color: #2346f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.video-react {
  background: transparent !important;
}

select {
  max-width: 100%;
  min-width: 0;
  font: inherit;
  display: block;
  background: none;
  -webkit-tap-highlight-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 12px 16px;
  width: 100%;
  margin: 12px 0;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: NeurialGrotesk, sans-serif !important;
  color: #696969;
  font-size: 18px;
  font-weight: normal;
  outline: none;
}
select:hover {
  border-color: rgba(0, 0, 0, 1);
}

.MuiCardActionArea-focusHighlight {
  background: transparent !important;
}

.MuiCard-root .MuiInputLabel-outlined.MuiFormLabel-filled {
  background: white !important;
}

/* /////////////
//  Popover 
//////////// */

div.MuiPaper-elevation8:not(.MuiCard-root),
div.MuiPaper-elevation1:not(.MuiCard-root) {
  box-shadow: 0 4px 4px rgba(132, 132, 132, 0.03);
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  transform: translateX(-2px);
}

li.MuiMenuItem-root {
  font-size: 14px;
}
#menu-funding {
  z-index: 2000 !important;
}

/* ///////////
//  Cards
//////////// */

.card-hover {
  transition: border-color 0.25s !important;
}
.card-hover:hover {
  border-color: rgb(35, 70, 242);
}

.MuiCard-root.card--min-width {
  min-width: 375px;
}

/* ////////////
//  Forms
//////////// */

.MuiOutlinedInput-notchedOutline {
  transition: 0.2s;
}

.MuiOutlinedInput-multiline {
  padding: 0 !important; /* being taken care for inside */
}

/* ////////////
//  Tables
//////////// */

@media (min-width: 600px) {
  .visible-sm {
    display: none !important;
  }
}

@media (max-width: 599px) {
  .hidden-sm {
    display: none !important;
  }

  table {
    min-width: 0 !important;
  }

  /* avatar with name */
  .table-dashboard--approvals tbody th,
  .table-dashboard--pending tbody th,
  .table-dashboard--approved tbody th,
  .table-dashboard--declined tbody th,
  .table-dashboard--recentlyApproved tbody th,
  .table-dashboard--requiresAction tbody th {
    width: 100%;
  }

  /* breaking tables down for mobile */
  .table-dashboard--approvals tbody tr,
  .table-transactions tbody tr {
    display: block;
    box-sizing: border-box;
  }
  .table-dashboard--approvals tbody th,
  .table-transactions tbody th {
    display: block;
    box-sizing: border-box;
  }
  .table-dashboard--approvals tbody td,
  .table-transactions tbody td {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  .table-cell-mobile-row-padded {
    padding-left: 53px !important;
    text-align: left !important;
  }
}

/* ////////////
//  Tabs
//////////// */

.MuiTab-textColorInherit {
  min-width: auto;
  text-transform: capitalize;
  color: black;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  padding: 0 0 20px;
  margin-right: 32px;
  opacity: 1 !important;
}
.MuiTab-textColorInherit .MuiTab-wrapper {
  line-height: 25px;
}
.MuiTab-textColorInherit:hover .MuiTab-wrapper {
  /* color: #2346f2; */
}
.MuiTab-textColorInherit .MuiTab-root {
  font-weight: 500;
}
.MuiTab-textColorInherit:last-child {
  margin-right: 0;
}
.MuiTab-textColorInherit.Mui-selected {
  /* color: blue; */
}

/* ////////////
//  Buttons
//////////// */

.MuiButton-contained {
  padding-right: 15px !important;
}
.MuiButton-contained:hover {
  box-shadow: none !important;
}

/* ////////////
//  Skeleton
//////////// */

.MuiSkeleton-text {
  transform: none !important;
}

#mouse-over-popover {
  z-index: 2000 !important;
}

/* ////////////
//  Modal
//////////// */

body.modal {
  overflow: hidden;
}

/* //////////////
// Forms
//////////// */

.MuiInputBase-root,
.MuiInputLabel-root,
.MuiInputBase-input {
  font-size: 14px !important;
}

.MuiOutlinedInput-root {
  border-radius: 6px !important;
}
.MuiSelect-outlined {
  border-radius: 6px !important;
}

.MuiOutlinedInput-root fieldset {
  border-radius: 6px !important;
}

.MuiOutlinedInput-root:hover fieldset {
  border-color: #686af9;
}

.react-tel-input .form-control {
  width: 100% !important;
  outline: none;
}

.react-tel-input .form-control:hover,
.react-tel-input .form-control:focus {
  border-color: #686af9;
  border-width: 1px;
  box-shadow: none;
}

/* /////
// New fixes
////////////////// */

.react-tel-input .special-label {
  display: none !important;
}

/* /////
// Gradients
////////////////// */

.tac {
  text-align: center;
}

.text-gradient {
  background: linear-gradient(90deg, #bb7c8f, #4611f8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-size: 20px; */
  /* font-weight: 500; */
}

.text-primary {
  color: #4e40ef;
}

label .MuiCheckbox-root {
  margin-top: -8px;
  margin-right: 3px;
}

/* /////
// TERMLY COOKIE BANNER STYLES
////////////////// */
.t-consentPrompt {
  max-width: 291px !important;
  background-color: rgb(255, 255, 255);
  border: 0.5px solid #e6e9ec !important;
  box-shadow: 0 0 3px 1.5px rgba(0, 0, 0, 0.06) !important;
  border-radius: 8px !important;
}

.t-consentPrompt div[class*='termly-styles-buttons'] {
  display: flex !important;
}
.t-consentPrompt div[class*='termly-styles-buttons'] button {
  width: unset !important;
  min-width: unset !important;
}
.t-consentPrompt .t-preferencesLink {
  font-size: 12px !important;
}

.edgebutton-foreignobject {
  overflow: visible;
}

/* /////
// REACT PHONE INPUT
////////////////// */
.react-tel-input .flag {
  height: 20px;
}
.country-list {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08) !important;
  border-radius: 6px !important;
}
.country-list li {
  transition: all 0.3s ease-out;
  overflow: hidden;
  padding-bottom: 4px !important;
}
.country-list li:hover {
  background-color: #fff !important;
  color: #4e40ef;
}
.country-list li[aria-selected*='true'] {
  background-color: #fff !important;
  color: #4e40ef;
}
/* /////
// REACT FLOW RENDERER
////////////////// */
.react-flow__attribution {
  display: none;
}
