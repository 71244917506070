/*
  NOTIFICATION CONTAINER
*/
.notification-container {
  box-sizing: border-box;
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 999999;
  width: 392px;
  padding: 0;
  max-height: calc(100% - 30px);
}

.notification {
  box-sizing: border-box;
  padding: 18px 24px 16px 70px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.2em;
  position: relative;
  margin-top: 15px;
  background-color: #1a1a1a;
  border-radius: 8px;
  display: flex;
  align-items: center;
  min-width: 100%;
}

/*
  NOTIFICATION MESSAGE
*/
.notification .notification-message {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #c4c4c4;
  width: 100%;
}

.notification .notification-message .message {
  width: 100%;
}

/*
  NOTIFICATION CUSTOM CONTAINER
*/
.notification .notification-message .customContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.notification .notification-message .customContainer .customContainer__message {
  margin-bottom: 0;
  margin-right: 4px;
}

.notification .notification-message .customContainer__message__title {
  color: white;
}

.notification .notification-message .customContainer__closIcon {
  border: 1px solid transparent;
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  transition: all 0.3s ease-out;
  margin-bottom: 3px;
}

.notification .notification-message .customContainer__closIcon:hover {
  border: 1px solid #595959;
}

/*
  NOTIFICATION:BEFORE 
*/
.notification:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 18px;
  width: 24px;
  height: 24px;
  margin-top: -14px;
  display: block;
  font-family: 'Notification';
  font-size: 28px;
  text-align: center;
  line-height: 28px;
}

/*
  NOTIFICATION - SUCCESS:BEFORE 
*/
.notification-success:before {
  background-image: url('../img/Success-new.svg');
}

/*
  NOTIFICATION - ERROR:BEFORE 
*/
.notification-error:before {
  background-image: url('../img/Error-new.svg');
}

/*
  NOTIFICATION - INFO:BEFORE 
*/
.notification-info:before {
  background-image: url('../img/Info.svg');
}

/*
  TODO - Clean up if it's deprecated styles
*/
.notification-success:focus {
  display: none;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}
